@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap');
@import url("./styles/common.css");
@import url("./styles/elements.css");

@import url("./styles/sections/faq.css");
@import url("./styles/sections/why.css");
@import url("./styles/sections/landing.css");


footer {
  gap: 1rem;
  width: 100%;
  display: flex;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
  background-color: #131417;
}

footer a {
  all: unset;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  footer {
    gap: 1.5rem;
    flex-direction: column;
    height: initial;
  }
}


.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's above other content */
}

.loading-content {
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.ethereum-logo {
  width: 100px; /* Adjust size as needed */
  margin-bottom: 20px;
}

/* .loading-content p {
  animation: fadeInOut 2s ease-in-out infinite;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  25% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 0; }
} */

.loading-content p {
  animation: fadeInOut 2s ease-in-out infinite, colorCycle 5s linear infinite;
}

/* This controls the fade in/out effect */
@keyframes fadeInOut {
  0% { opacity: 0; }
  25% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 0; }
}

/* This controls the text color cycling through RGB */
@keyframes colorCycle {
  0% { color: rgb(255, 0, 0); }    /* Red */
  33% { color: rgb(0, 255, 0); }   /* Green */
  66% { color: rgb(0, 0, 255); }   /* Blue */
  100% { color: rgb(255, 0, 0); }  /* Back to Red */
}
