#why-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* This ensures the content is centered horizontally */
  padding: 3rem 12rem;
  gap: 2rem;
  text-align: center; /* This will center text content within the flex container */
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  #why-section {
    padding: 3rem 0.25rem;
  }
}

#why-section-content-container {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items within this container as well */
  gap: 2rem;
}

.why-section-content {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  /* If you want text centered inside each content block: */
  text-align: center;
}
