/* #heading {
  display: flex;
  align-items: center;
  padding: 1.5rem 9.5rem;
  justify-content: space-between;
  background-image: radial-gradient(ellipse at center, hsl(289deg 78% 30% / 35%), transparent 60%);
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  #heading {
    gap: 2rem;
    text-align: center;
    flex-direction: column;
  }
}

#heading-text {
  gap: 1.5rem;
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  flex-direction: column;
}

#heading-cta {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#heading-image {
  flex-grow: 1;
  display: flex;
  justify-content: end;
}

#heading-image>img {
  width: 350px;
}

#heading-cta-action {
  gap: 1rem;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
} */



/* #heading {
  display: flex;
  align-items: center;
  padding: 1.5rem 9.5rem;
  justify-content: space-between;
  background-image: radial-gradient(ellipse at center, hsl(289deg 78% 30% / 35%), transparent 60%);
} */


#heading {
  display: flex;
  align-items: center;
  padding: 1.5rem 9.5rem;
  justify-content: center; /* or space-around */
  background-image: radial-gradient(ellipse at center, hsl(289deg 78% 30% / 35%), transparent 60%);
}

#heading-text, #heading-image {
  flex-basis: 1%; /* Adjust as needed */
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  #heading {
    gap: 2rem;
    text-align: center;
    flex-direction: column;
  }
}

#heading-text {
  gap: 1.5rem;
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  flex-direction: column;
  max-width: 350px; /* Adjust as needed */
}

#heading-cta {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#heading-image {
  flex-grow: flex;
  /* display: flex; */
  /* justify-content: end; */
  justify-content: center;
}

#heading-image>img {
  width: 350px;

}

/* #heading-image {
  justify-content: center;
} */


#heading-cta-action {
  gap: 1rem;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.container {
  max-width: 1200px; /* or any other suitable width */
  margin: 0 auto; /* centers the container */
  padding: 0 15px; /* optional padding */
}
