.simmering-text {
  color: transparent;
  background-clip: text;
  font-weight: 700;

  -webkit-background-clip: text;
  background-image: linear-gradient(to right, #743F9E, #BFA3DA);
}


.center-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

