* {
  margin: 0;
  padding: 0;
  color: #ffffffe6;
  font-family: 'Inter', sans-serif;
}


h1 {
  font-size: 3.5rem;
  font-weight: 700;
}



h3 {
  font-size: 2em;
  color: transparent;
  font-size: 56px;
  font-weight: 700;
  line-height: 1.125;
  letter-spacing: 0.15px;
  width: min-content;
}

h6 {
  all: unset;
  font-size: 1.25rem;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  h6 {
    font-size: 1rem;
  }
}

p {
  color: #b2b2b2;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
}

body {
  color: white;
  display: flex;
  background: #0e0e10;
  flex-direction: column;
}


button {
  all: unset;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  border-radius: 8px;
  padding: 13px 18px;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  line-height: 1;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: #ededef;
  color: #161618;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgb(0 0 0/0.05);
  -webkit-animation: animation-plwpox 300ms ease;
  animation: animation-plwpox 300ms ease;
}

button:hover {
  background: #bfbfbf;
}

.content div {
  all: revert;
}
